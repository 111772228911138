import React from 'react'
import { useTrans } from 'hooks'
import { priceNotation } from 'utils/safe'
import { Button, ImageBox, Label, QuantityHandler } from 'components/atoms'

interface Props {
  prodotto: {
    cantina?: {
      nome: string
    }
    nome: string
    nomeFantasia: string
    prezzo: number
    prezzoScontato: number
    mainImage?: string
    thumbnailImage?: string
  }
  checkout?: boolean
  bordered?: boolean
  quantita: number
  omaggio: boolean
  onRemove?: () => void
  onQuantitaChange?: (newQuantita: number, action: string) => void
  size?: '' | 'sm'
  message?: string
  className?: string
  errore_vendibilita_estero?: boolean
}

const ProdottoCardCarrello = (props: Props) => {
  const {
    prodotto = {
      cantina: null,
      nome: '',
      nomeFantasia: '',
      prezzo: 0,
      prezzoScontato: 0,
      mainImage: '',
      thumbnailImage: '',
    },
    checkout = false,
    bordered = false,
    quantita = 1,
    omaggio = false,
    onQuantitaChange = () => {},
    onRemove = () => {},
    size = '',
    className = '',
    message = '',
    errore_vendibilita_estero = false,
  } = props

  const t = useTrans()

  return (
    <div
      className={`prodotto-card-carrello${size ? ` prodotto-card-carrello--${size}` : ''}${
        bordered ? ' prodotto-card-carrello--bordered' : ''
      }${checkout ? ' prodotto-card-carrello--checkout' : ''}${className ? ` ${className}` : ''}`}
    >
      <ImageBox
        src={prodotto.mainImage}
        maxWidth={checkout ? 68 : 135}
        maxHeight={checkout ? 90 : 180}
        layout="intrinsic"
        classPrefix="prodotto-card-carrello"
      />
      <div className="prodotto-card-carrello__content">
        <p className="prodotto-card-carrello__cantina">{prodotto.cantina?.nome}</p>
        <p className="prodotto-card-carrello__title">{prodotto.nomeFantasia}</p>
        <div className="prodotto-card-carrello__price">
          {!omaggio ? (
            <>
              <span className="prodotto-card-carrello__price__final">
                {priceNotation(prodotto.prezzoScontato)}
              </span>
              {prodotto.prezzo !== prodotto.prezzoScontato && (
                <span className="prodotto-card-carrello__price__full">
                  {priceNotation(prodotto.prezzo)}
                </span>
              )}
            </>
          ) : (
            <Label
              label={t('In omaggio').toUpperCase()}
              size="sm"
              variant="dark"
              className=".prodotto-card-carrello__omaggio"
            />
          )}
        </div>
        {!omaggio && (
          <div className="prodotto-card-carrello__quantity">
            {checkout ? (
              <>
                {t('Quantità')}: {quantita}
              </>
            ) : (
              <>
                <QuantityHandler size="sm" value={quantita} onValueChange={onQuantitaChange} error={message && message !== ''}/>
                <Button
                  label={size === 'sm' ? t('Rimuovi') : t('Rimuovi articolo')}
                  labelMobile={t('Rimuovi')}
                  size="sm"
                  variant="ghost"
                  colorVariant="gray"
                  onClick={onRemove}
                />
              </>
            )}
          </div>
        )}
        {message && message !== '' && <div className="prodotto-card-carrello__error">
          <span className="prodotto-card-carrello__error__message">{message}</span>
        </div>}
        {errore_vendibilita_estero && 
          <div className="prodotto-card-carrello__error__estero">
            <img src='/images/alert_icon.png'></img>
            <div>
              <p className="prodotto-card-carrello__error__message__italian">
                {t("Non disponibile per l'estero")}
              </p>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default ProdottoCardCarrello
